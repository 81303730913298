import React, { Component } from 'react';
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import { Link, BrowserRouter } from 'react-router-dom'
import moment from 'moment'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import _ from 'lodash'
import $ from 'jquery'
import ReportView from './ReportView';
import AgentPayoutPdf from './Pdf/AgentPayoutPdf';

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2})
const lineb = { borderBottom:'1px solid #cccccc', padding:'5px'}
const linehb = {borderBottom:'1px solid #cccccc', padding:'5px', fontSize:'14px', fontWeight:'bold'}
const linebl = {borderBottom:'1px solid #cccccc', borderLeft:'1px solid #cccccc', padding:'5px'}
const linehbl = {borderBottom:'1px solid #cccccc', borderLeft:'1px solid #cccccc', padding:'5px', fontSize:'14px', fontWeight:'bold'}
const linebr = { borderBottom:'1px solid #cccccc', borderRight:'1px solid #cccccc', padding:'5px'}
const linehbr = { borderBottom:'1px solid #cccccc', borderRight:'1px solid #cccccc', padding:'5px', fontSize:'14px', fontWeight:'bold'}
const title = {
    border:'1px solid #000000',
    textAlign:'center',
    padding:'5px',
    fontSize:'18px',
    fontWeight:'bold'
}
const heading = {
    fontSize:'14px',
    fontWeight:'bold'
}

export default class AgentPayouts extends Component {
    constructor() {
        super()
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()
        this.state = {
            formItems: {
                operator:'', username:'', game: 0, startDate: moment(), endDate: moment(),
                curuser:this.auth.getUser(), token:this.auth.getToken()
            },
            operators: [], operator:{},
            agents: [], games:[], retailers:[],
            totals:{totalGross:0, totalNet:0, totalWins:0},
            agentPayout: [],
            tbStruct:'',
            loader: false,
            startDate: moment(),
            endDate: moment()
        }
    }

    componentDidMount() {
        this.setState({ loader: true })
        let systemDefine = (this.auth.getRole() == "Admin" || this.auth.getRole() == "Super") ? '/usertypes/1' : '/usertypesoperator/' + this.auth.getUser() + '/' + 1
        this.getGames(systemDefine)
    }

    componentDidUpdate(nextProps) {
        if (nextProps !== this.props) {
            this.setState({ loader: true })
                     
        }
    }

    getOperators = (systemDefine) => {
        if (systemDefine != undefined) {
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + systemDefine)
            .then(res   => {
                if(isNaN(res.data)){
                    let operators = this.auth.decryptData(res.data)
                    let formItems = this.state.formItems
                    let operator = ''
                    if(operators[0] != undefined){
                        operator = operators[0].username
                        formItems['operator'] = operator
                        this.getAgents(operator)
                    }
                
                    this.setState({ operators, formItems })
                }else{
                    this.auth.doAlert(parseInt(res.data))
                }
            })
        }
    }

    getAgents = (operator) => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() 
        + '/agentdetails_rep/' + operator)

        .then(res => {
            if (isNaN(res.data)) {
                let agents = this.auth.decryptData(res.data)

                let formItems = this.state.formItems
                if(agents[0] != undefined){
                    formItems['username'] = agents[0].username
                }
                
                //alert(JSON.stringify(agents))
                this.setState({ formItems, agents})
                this.getRetailers()
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        })
    }

    getRetailers = (operator) => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() 
        + '/user/5')

        .then(res => {
            if (isNaN(res.data)) {
                let retailers = this.auth.decryptData(res.data)

                //alert(JSON.stringify(retailers))
                this.setState({ retailers })
                //this.getReports()
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        })
    }

    getGames = (systemDefine) =>{
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/game')
        .then(res => {
            let games = this.auth.decryptData(res.data)
            
            this.setState({ games })

            this.getOperators(systemDefine)
        })
    }

    getReports = (agent) => {
        ReactDOM.render('Loading...', document.getElementById('agentPayoutInner'))
        let formItems = this.state.formItems

        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'report/wapp/agentpayout'))
        .then((res) => {
            if (isNaN(res.data)) {
                let resdata = this.auth.decryptData(res.data)

                let tickets = _.groupBy(resdata, 'retailer')
                let agentPayout = {}
                Object.keys(tickets).map(tick => {
                    let groups = _.groupBy(tickets[tick], 'game')

                    agentPayout[tick] = _.map(groups, function(value, key) {
                        return {
                            game: key,
                            gross:  _.reduce(value, function(total, o) { 
                                return total += o.gross;
                            }, 0),
                            wins:  _.reduce(value, function(total, o) { 
                                return total += o.wins;
                            }, 0)
                        }
                    })
                })
                
                this.setState({ agentPayout })
                ReactDOM.render(this.showTableData(agentPayout), 
                    document.getElementById('agentPayoutInner'))
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch((error)=>{alert(error); this.setState({ loader: false });})
    }
    
    showTableData = (agentPayout) => {
        let formItems = this.state.formItems
        
        let itm = []
        let totalGross = 0
        let totalNets = 0
        let totalWins = 0

        Object.keys(agentPayout).map(key => {
            let lineGross = 0, lineNets = 0, lineWins = 0

            itm.push(<tr><td></td><td></td><td></td><td></td><td></td></tr>)
            itm.push(<tr><td></td><td colspan="4" style={title}>{this.retailerFormat(key)}</td></tr>)
            itm.push(<tr><td></td>
            <td width="120px" className="text-right" style={linehbl}>Gross</td>
            <td width="120px" className="text-right" style={linehb}>Nets</td>
            <td width="120px" className="text-right" style={linehb}>Wins</td>
            <td width="120px" className="text-right" style={linehbr}>Pay In/Out</td></tr>)

            agentPayout[key].map(rdata => {
                let net = rdata.gross - (rdata.gross * .2)
                let win = (isNaN(rdata.wins)) ? 0.00 : rdata.wins
                let pay = net - win
                let payinout = (pay < 0) ? '(' + numformat.format(Math.abs(pay)) + ')' : numformat.format(pay)

                totalGross += rdata.gross
                totalNets += net
                totalWins += win
                lineGross += rdata.gross
                lineNets += net
                lineWins += win

                itm.push(<tr><td className="table-bordered">{this.gameFormat(rdata.game, rdata)}</td>
                <td className="text-right" style={lineb}>{numformat.format(rdata.gross)}</td>
                <td className="text-right" style={lineb}>{numformat.format(net)}</td>
                <td className="text-right" style={lineb}>{numformat.format(win)}</td>
                <td className="text-right" style={linebr}>
                    {(pay < 0) ? <b className="text-danger">{payinout}</b> : <b className="text-success">{payinout}</b>}
                </td></tr>)
            })

            let linepay = lineNets - lineWins
            let linepayinout = (linepay < 0) ? '(' + numformat.format(Math.abs(linepay)) + ')' : numformat.format(linepay)
            itm.push(<tr><td className="table-bordered text-right" style={heading}>Line Total: </td>
                <td className="text-right" style={linehb}>{numformat.format(lineGross)}</td>
                <td className="text-right" style={linehb}>{numformat.format(lineNets)}</td>
                <td className="text-right" style={linehb}>{numformat.format(lineWins)}</td>
                <td className="text-right" style={linehbr}>
                    {(linepay < 0) ? <b className="text-danger">{linepayinout}</b> : <b className="text-success">{linepayinout}</b>}
                </td></tr>)
            itm.push(<tr><td colSpan="5">&nbsp;</td></tr>)
        })

        let totals = {gross:totalGross, wins:totalWins, net:totalNets}
        this.setState({ totals, tbStruct: itm })

        return(
            <div className="row">
                <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 bg-primary text-center" style={{paddingTop:"5px",fontSize:"18px",textTransform:"uppercase"}}>
                    {this.agentFormat(formItems.username)}
                </div>
                <div className="col-xs-12"><hr className="hrsp" /></div>
                <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
                    <table id="" className="table table-condensed table-responsive table-striped">
                        <tbody>
                            <tr>
                                <td width="100px" align="right">Operator:</td>
                                <td width="150px"><b>{this.formatOperator(this.state.formItems.operator, {})}</b></td>
                                <td width="100px" align="right">Total Gross: </td>
                                <td width="150px"><b>{numformat.format(totalGross)}</b></td>
                            </tr>
                          
                            <tr>
                                <td width="100px" align="right">Start Date: </td>
                                <td width="150px"><b>{this.state.startDate.format('MMMM D, YYYY')}</b></td>
                                <td width="100px" align="right">Total Wins: </td><td width="150px"><b>{numformat.format(totalWins)}</b></td>
                            </tr>
                            <tr>
                                <td width="100px" align="right">End Date: </td>
                                <td width="150px"><b>{this.state.endDate.format('MMMM D, YYYY')}</b></td>
                                <td width="100px" align="right">Total Net: </td><td width="150px"><b>{numformat.format(totalNets)}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-xs-12"><hr className="hrsp" /></div>

                <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2" id="games">
                    <table className="table table-responsive table-condensed">{itm}</table>
                </div>
            </div>
        )
    }
    
    agentFormat = (cell) => {
        let agent = this.state.agents.filter(ag => ag.username == cell)
        let agentname = (agent[0] !== undefined) ? agent[0].fullname + ' (' + cell + ')'  : cell

        return agentname
    }
    retailerFormat=(cell)=>{
        let retailer = this.state.retailers.filter(ret => ret.username == cell)
        let retname = (retailer[0] !== undefined) 
        ? retailer[0].firstname + ' ' + retailer[0].lastname + ' (' + cell + ')' : cell

        return retname
    }    
    gameFormat = (cell, row) => {
        let game = this.state.games.filter(ga => ga.id == cell)
        let gamename = (game[0] !== undefined) ? game[0].gamename   : cell

        return gamename
    }

    formatOperator = (cell, row) => {
        let operator = this.state.operators.filter(op => op.username == cell)
        let operatorname = (operator[0] !== undefined) ? operator[0].fullname : cell

        return operatorname
    }

    handleEvent = (event, picker) => {
        let formItems=this.state.formItems
        let startDate = picker.startDate
        let endDate = picker.endDate

        formItems['startDate'] = startDate
        formItems['endDate'] = endDate

		this.setState({ startDate, endDate, formItems })
    }
    
    handleChange = (e) => {
        let formItems = this.state.formItems
         formItems[e.target.name] = e.target.value

        if(e.target.name == 'operator'){
            this.getAgents(e.target.value)
        }else if(e.target.name=="username"){
            //this.getReports(e.target.value)
        }

        this.setState({ formItems })
    }

    goBack = () => {
        ReactDOM.render(<ReportView  />, document.getElementById('generalReport_inners'))
    }

    printPreview = () => {
        let formItems = this.state.formItems
        let totals = this.state.totals
        
        let linepay = numformat.format(totals.gross - totals.net)

        let data = {
            agent:this.agentFormat(formItems.username),
            operator:this.formatOperator(formItems.operator) + ' ('+formItems.operator +')', 
            startDate:formItems.startDate, gross: totals.gross,
            wins: totals.wins, net: totals.net,
            endDate:formItems.endDate, total: linepay
        }
        
        ReactDOM.render(<AgentPayoutPdf rowData={this.state.tbStruct} data={data} />
            ,document.getElementById('generalReport_inners'))
    }
    
    loading = () => <div id="loader"></div>

    render() {
            let ranges= {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            }
            let start = this.state.startDate.format('MMMM D, YYYY');
            let end = this.state.endDate.format('MMMM D, YYYY');
            let label = start + ' - ' + end;
           
            if (start === end) {label = start;}
            
            return (
                <div className="row">
                    <article className="col-xs-12">
                        <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                            <header>
                                <span class="widget-icon"> <i class="fa fa-book"></i> </span>
                                <h2><strong>Agent Payout</strong> </h2>
                            </header>
                            <div className="widget-body">

                                <div className="btn-group btn-group-sm">
                                    <button className="btn btn-default">Operator: </button>
                                    <select id="operator" name="operator" className="btn btn-default form-control" style={{ width: '200px', height:'30px' }}
                                        onChange={this.handleChange} value={this.state.formItems.operator} >
                                        {this.state.operators.map(op => (
                                            <option value={op.username}>{op.fullname}</option>
                                        ))}
                                    </select>
                                            <br /><br />
                                    <button className="btn btn-default">Draw Date Range: </button>
                                    <DatetimeRangePicker startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        ranges={ranges} onEvent={this.handleEvent}
                                        className="selected-date-range-btn btn btn-default">
                                        <div className="pull-left">
                                            <i className="fa fa-calendar" />&nbsp; &nbsp;<span>{label}</span>
                                        </div>
                                        <div className="pull-right">&nbsp; &nbsp;<i className="fa fa-angle-down" /></div>
                                    </DatetimeRangePicker>

                                    <button className="btn btn-default">Agent: </button>
                                    <select id="username" name="username" className="btn btn-default form-control" style={{ width: '200px', height:'30px' }}
                                        onChange={this.handleChange} value={this.state.formItems.username} >
                                        {this.state.agents.map(op => (
                                            <option value={op.username}>{op.fullname}</option>
                                        ))}
                                    </select>
                                    <button onClick={this.getReports} className="btn btn-info">Display</button>
                                </div>
                                <div class="btn-group pull-right">
                                <div className="btn-group btn-group-sm pull-right" >
                                    <button className="btn btn-primary " style={{borderTopLeftRadius:"0px",borderBottomLeftRadius:"0px"}} onClick={this.printPreview}>Preview</button>
                                </div>
                                <div className="btn-group btn-group-sm pull-right" >
                                    <button className="btn btn-warning " id="back"  style={{borderTopRightRadius:"0px",borderBottomRightRadius:"0px"}} onClick={this.goBack}>Go Back</button>
                                </div>
                                </div>
                                <div className="col-xs-12"><hr className="hrsp" /></div>  
                                <div id="agentPayoutInner"></div>
                            </div>
                        </div>
                    </article>
                </div>
            )
    }
}