import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Auth from "../../common/Auth";
import Config from "../../Config";
import AgentLists from "../AgentLists";
import { AgentListReport } from "./ReportExport";

function AgentListPdf(props) {
    const [rowData, setRowData] = useState(props?.rowData || []);
    const [data, setData] = useState(props.data);

    const auth = new Auth();
    const config = new Config();
    const $http = config.DConnect();

    const colstyle = { fontSize: "30px", display: "none" };
    const numformat = new Intl.NumberFormat("en-GB", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });

    useEffect(() => {
        setData(props.data);
        setRowData(props?.rowData || []);

        getCompany();
    }, [props.data, props.rowData]);

    const getCompany = () => {

        $http.get('admin/wapp/setup').then(res => {
            let company = auth.decryptData(res.data);

            showReport(company);
        })
    }

    const showReport = async (company) => {
        const pdf = new jsPDF("p", "mm", "a4", true);
        pdf.setFont("Helvetica")

        let props = {
            title: `${data?.operator} AGENTS LIST REPORT`,
            subject: `${data?.operator} AGENTS  LIST REPORT`,
            keywords: 'draw, screen, alpha, lotto',
            creator: 'Mojave Consultancy',
            company: company
        },

        pdfdata = await AgentListReport(pdf, props, data, rowData);

        document.getElementById('pdfPreview').src = pdfdata;
    }

    const goBack = () => {
        ReactDOM.render(
            <AgentLists />,
            document.getElementById('generalReport_inners')
        );
    }

    return (
        <div className="row">
            <article className="col-xs-12">
                <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                    <header>
                        <span class="widget-icon"> <i class="fa fa-book"></i> </span>
                        <h2><strong>{data?.operator} Agents List Report</strong> </h2>
                    </header>
                    <div>
                        <div className="jarviswidget-editbox"></div>
                        <div className="widget-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-10">
                                    <iframe id="pdfPreview" type="application/pdf" style={{ 'width': '100%', 'height': '550px', 'border': 'none' }}></iframe>
                                </div>
                                <div className="col-xs-12 col-sm-2">
                                    <div className="btn-group btn-group-vertical btn-group-sm">
                                        <button className="btn btn-warning" onClick={goBack}>
                                            <i className="fa fa-backward"></i> Back
                                        </button>
                                        {/* <button className="btn btn-success"> <i className="fa fa-file-excel-o"></i> Export Excel</button>
                                        <button className="btn btn-success"> <i className="fa fa-file-archive-o"></i> Export CSV</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    );
}

export default AgentListPdf;

// const colstyle = {
//     fontSize:'30px',
//     display:'none'
// }

// let images={}

// function showReport(img, row, data, comp){

//     const pdf = new jsPDF("p", "mm", "a4")

//     pdf.setProperties({
//         title: 'LIST OF AGENTS for ' + data,
//         subject: 'LIST OF AGENTS for ' + data,
//         author: comp.companyName,
//         keywords: 'draw, screen, alpha, lotto',
//         creator: 'Mojave Consultancy'
//     })

//     pdf.autoTable({
//         html: '#tbCompany',
//         theme: 'plain',
//         styles: {fontSize: 16, fontStyle:'bold', overflow: 'linebreak'},
//         columnStyles: {
//             0 :{cellWidth:30},
//             1: {halign: 'center'},
//             2: {halign:'right', valign:'bottom', cellWidth:30, fontSize:8, fontStyle:'normal'}
//         }
//     })

//     if(img !='' && img !=undefined){
//         let tp = 'PNG'
//            if(img.indexOf('jpeg') != -1 && img.indexOf('jpg') != -1){
//                tp = 'JPEG'
//            }
//            pdf.addImage(img,tp,15,10,0,23)
//         }
//     pdf.line(14, 40, 196, 40)
//     let y=42
//     row.map(rw =>{
//         let uname = rw.username.replace(/ /g, '_')
//         pdf.autoTable({
//             html: '#tb_' + uname,
//             theme: 'striped',
//             //styles: {fontSize: 18, fontStyle:'bold', overflow: 'linebreak'},
//             columnStyles: {
//                 0: {cellWidth:30}, 1: {cellWidth:30, halign:'right'},
//                 2: {cellWidth:40, fontStyle:'bold'}, 3: {cellWidth:30, halign:'right'}, 4: {cellWidth:40, fontStyle:'bold'}
//             }
//         })

//         /*if(images[uname] !='' && images[uname] != undefined){
//             let tp = 'PNG'
//             if(images[uname].indexOf('jpeg') != -1 || images[uname].indexOf('jpg') != -1){
//                 tp = 'JPEG'
//             }
//             pdf.addImage(images[uname],tp,15,y,0,29)

//         }
//         y+=37*/
// })

//     var data = pdf.output('datauristring')
//     document.getElementById('pdfPreview').src = data
// }

// function exportTableToExcel(tableID, filename = ''){
//     var downloadLink;
//     var dataType = 'application/vnd.ms-excel';
//     var tableSelect = document.getElementById(tableID);
//     var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
//     filename = filename?filename+'.xls':'excel_data.xls';
//     downloadLink = document.createElement("a");

//     document.body.appendChild(downloadLink);

//     if(navigator.msSaveOrOpenBlob){
//         var blob = new Blob(['\ufeff', tableHTML], {
//             type: dataType
//         });
//         navigator.msSaveOrOpenBlob( blob, filename);
//     }else{
//         downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
//         downloadLink.download = filename;
//         downloadLink.click();
//     }
// }
// function download_csv(csv, filename) {
//     var csvFile;
//     var downloadLink;
//     csvFile = new Blob([csv], {type: "text/csv"});
//     downloadLink = document.createElement("a");
//     downloadLink.download = filename;
//     downloadLink.href = window.URL.createObjectURL(csvFile);
//     downloadLink.style.display = "none";
//     document.body.appendChild(downloadLink);
//     downloadLink.click();
// }
// function exportTableToCsv(tableID, filename = ''){
//         var csv = [];
//         var rows = document.querySelectorAll("table tr");

//         for (var i = 0; i < rows.length; i++) {
//             var row = [], cols = rows[i].querySelectorAll("td, th");

//             for (var j = 0; j < cols.length; j++)
//                 row.push(cols[j].innerText);

//             csv.push(row.join(","));
//         }
//         download_csv(csv.join("\n"), filename);
// }
// export default class AgentListPdf extends Component {
//     constructor(props) {
//         super(props)

//         this.config = new Config()
//         this.auth = new Auth()
//         this.$http = this.config.DConnect()

//         this.state = {
//             filterData: {},
//             agents: this.props.agents,
//             companySetup: '',
//             operator: this.props.operator
//         }
//     }

//     componentDidMount() {
//         this.getCompanySetup()
//     }

//     componentDidUpdate(nextProps){
//         if(nextProps.agents !== this.props.agents){
//             let agents = nextProps.agents
//             let operator = nextProps.operator

//             this.setState({ agents, operator })

//             this.getCompanySetup()
//         }
//     }

//     goBack = () => {
//         ReactDOM.render(
//             <AgentLists/>,
//             document.getElementById('generalReport_inners')
//         )
//     }

//     getCompanySetup = () => {
//         this.$http.get('admin/wapp/setup')
//         .then(res => {
//             let companySetup = this.auth.decryptData(res.data)

//             this.setState({ companySetup })

//             //reportData = this.state.reportData
//             let operator=this.state.operator
//             let agents=this.state.agents
//             /*agents.map(agt=>{
//                 let aurl='admin/wapp/image/custfiles/' +agt.username + '.'+ agt.filex*/
//                 let url = 'admin/wapp/image/custfiles/' + companySetup.logo

//                 /*this.$http.get(aurl, {responseType: 'arraybuffer'})
//                 .then((res) => {
//                     let aimg = new Buffer(res.data, 'binary').toString('base64')
//                     images[agt.username.replace(/ /g, '_')] = aimg

//                     if(agents[agents.length - 1].username == agt.username){*/
//                         this.$http.get(url, {responseType: 'arraybuffer'})
//                         .then((res) => {
//                             let img = new Buffer(res.data, 'binary').toString('base64')
//                             showReport(img, agents, operator, companySetup)
//                         }).catch((error)=>{showReport('', agents, operator, companySetup)})
//                     /*}
//                 })
//             })*/

//         }).catch((error)=>{alert(error)})
//     }
//     exportTableToExcels=()=>{

//         exportTableToExcel('datas', 'Agent-List')
//     }
//     exportTableToCsv=()=>{
//         exportTableToCsv('datas', 'Agent-List')
//     }

//     render() {
//         return (
//             <div className="row">
//                 <article className="col-xs-12">
//                     <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
//                         <header>
//                             <span class="widget-icon"> <i class="fa fa-book"></i> </span>
//                             <h2><strong>Agent List</strong> </h2>
//                         </header>
//                         <div>
//                             <div className="jarviswidget-editbox"></div>
//                             <div className="widget-body">
//                                 <div className="row">
//                                     <div className="col-xs-12 col-sm-10">
//                                         <iframe id="pdfPreview" type="application/pdf" style={{ 'width': '100%', 'height': '550px', 'border': 'none' }}></iframe>
//                                     </div>
//                                     <div className="col-xs-12 col-sm-2">
//                                         <div className="btn-group btn-group-vertical btn-group-sm">
//                                             <button className="btn btn-warning" onClick={this.goBack}>
//                                                 <i className="fa fa-backward"></i> Back
//                                             </button>
//                                             <button className="btn btn-success" onClick={this.exportTableToExcels}> <i className="fa fa-file-excel-o"></i> Export Excel</button>
//                                             <button className="btn btn-success" onClick={this.exportTableToCsv}> <i className="fa fa-file-archive-o"></i> Export CSV</button>
//                                        </div>
//                                     </div>
//                                     <div className="col-xs-12" id="datas">
//                                         <table id="tbCompany" className="table table-condensed" style={colstyle}>
//                                             <tbody>
//                                                 <tr>
//                                                     <td rowSpan="3">&nbsp;<img src={this.config.BaseURL()+this.state.url} /></td>
//                                                     <td>{this.state.companySetup.companyName}</td>
//                                                     <td rowSpan="3">
//                                                     {this.state.companySetup.address} <br />
//                                                     TEL: {this.state.companySetup.phone} <br />
//                                                     </td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td>LIST OF AGENTS</td>
//                                                 </tr>
//                                             </tbody>
//                                         </table>
//                                         {this.state.agents.map(agent => (
//                                         <table id={'tb_'+agent.username.replace(/ /g, '_')} className="table table-condensed" style={colstyle}>
//                                             <tbody>

//                                                 <tr>
//                                                     <td rowSpan="4"></td>
//                                                     <td>&nbsp;</td>
//                                                     <td>&nbsp;</td>
//                                                     <td>&nbsp;</td>
//                                                     <td>&nbsp;</td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td>Agent Name: </td>
//                                                     <td colSpan="3"><b>{agent.fullname}</b></td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td>Agent ID: </td>
//                                                     <td><b>{agent.username}</b></td>
//                                                     <td>Phone No.: </td>
//                                                     <td><b>{agent.mobile}</b></td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td>Location: </td>
//                                                     <td><b>{agent.location}</b></td>
//                                                     <td>Total Retailers: </td>
//                                                     <td><b>{agent.retailers}</b></td>
//                                                 </tr>
//                                             </tbody>
//                                         </table>
//                                         ))}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </article>
//             </div>
//         )
//     }

// }
