import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import jsPDF from 'jspdf';
import "jspdf-autotable";
import Auth from '../../common/Auth';
import Config from '../../Config';
import { DoReport } from './ReportExport';
import moment from 'moment';
import _ from 'lodash';
import AgentPayouts from '../AgentPayouts';

function AgentPayoutPdf(props) {

    const [rowData, setRowData] = useState(props.rowData);
    const [data, setData] = useState(props.data);

    const auth = new Auth();
    const config = new Config();
    const $http = config.DConnect();

    const colstyle = { fontSize: '30px', display: 'none' };
    const numformat = new Intl.NumberFormat("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 });

    useEffect(() => {

        setData(props.data);
        setRowData(props.rowData);

        getCompany();
    }, [props.data, props.rowData]);

    const getCompany = () => {

        $http.get('admin/wapp/setup').then(res => {
            let company = auth.decryptData(res.data);

            showReport(company);
        })
    }

    const showReport = (company) => {
        const pdf = new jsPDF("p", "mm", "a4", true);

        let props = {
            title: `AGENT PAYOUT REPORT`,
            subject: `AGENT PAYOUT REPORT`,
            keywords: 'draw, screen, alpha, lotto',
            creator: 'Mojave Consultancy',
            company: company
        },

            mainTable = {
                html: '#tbMaintable', startY: (60), theme: 'grid', styles: { fontSize: 8, showHead: 'everyPage' }, margin: { left: 5 },
                headStyles: { fillColor: '#efefef', textColor: '#000000', lineColor: '#000000', lineWidth: .1 },
                columnStyles: {
                    0: { cellWidth: 60 },
                    1: { fontStyle: 'bold', cellWidth: 30, halign: 'center' },
                    2: { halign: 'right', cellWidth: 30 },
                    3: { halign: 'right', cellWidth: 30 },
                    4: { halign: 'right', cellWidth: 30 }
                }
            },

            edata = {
                'Agent': data.agent,
                '':'',
                'Start Date': moment(data.startDate).format('DD MMM, YYYY'),
                'End Date': moment(data.endDate).format('DD MMM, YYYY'),
                'Total Gross': numformat.format(data.gross),
                'Total Wins': numformat.format(data.wins),
                'Total Net': numformat.format(data.net)
            },

            pdfdata = DoReport(pdf, props, mainTable, edata);

        document.getElementById('pdfPreview').src = pdfdata;
    }

    const goBack = () => {
        ReactDOM.render(
            <AgentPayouts />,
            document.getElementById('generalReport_inners')
        );
    }

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-10">
                <iframe id="pdfPreview" type="application/pdf" style={{ 'width': '100%', 'height': '80vh', 'border': 'none' }}></iframe>
            </div>
            <div className="col-xs-12 col-sm-2">
                <div className="btn-group btn-group-vertical btn-group-sm">
                    <button className="btn btn-warning" onClick={goBack}>
                        <i className="fa fa-backward"></i> Back
                    </button>
                    <button className="btn btn-success"> <i className="fa fa-file-excel-o"></i> Export Excel</button>
                    <button className="btn btn-success"> <i className="fa fa-file-archive-o"></i> Export CSV</button>
                </div>
            </div>
            <div className="col-xs-12">
                <table id="tbMaintable" className="table table-condensed" style={colstyle}>
                    <tbody>{rowData}</tbody>
                </table>
            </div>
        </div>
    );
}

export default AgentPayoutPdf;

