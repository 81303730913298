import React, { useEffect, useState } from "react";
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import moment from 'moment'
import ReactDOM from 'react-dom'
import Auth from "../common/Auth";
import CommissionList from "./CommissionList";
import Config from "../Config";
import axios from "axios";
import _ from "lodash";

function CommissionForm(props) {

    const auth = new Auth();
    const config = new Config();

    const [formItems, setFormItems] = useState({
        'game': 0, 'username': '', 'rate': 0, 'startDate': moment(),
        'endDate': moment(), 'curuser': auth.getUser(), 'token': auth.getToken()
    });
    const [games, setGames] = useState(props.games);
    const [agents, setAgents] = useState(props.agents);
    const [retailers, setRetailers] = useState(props.retailers);
    const [comDetails, setComDetails] = useState({});
    const [totals, setTotals] = useState({ 'total': 0 });
    const [loader, setLoader] = useState(false);

    const numformat = new Intl.NumberFormat("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    const ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    }
    let start = formItems.startDate.format('DD MMM, YYYY'),
        end = formItems.endDate.format('DD MMM, YYYY'),
        label = (start === end) ? start : start + ' - ' + end;

    useEffect(() => {
        setGames(props.games);
        setAgents(props.agents);
        setRetailers(props.retailers);

        getInitData();

    }, [props.games, props.agents, props.retailers]);

    const getInitData = () => {
        //config.DConnect()
        config.DConnect().get(`game/wapp/${auth.getUser()}/${auth.getToken()}/game`)
            .then(res => {
                let games = auth.decryptData(res.data);

                setGames(games);
            }).catch(error => alert(error.message));

        config.DConnect().get(`admin/wapp/${auth.getUser()}/${auth.getToken()}/agentdetails_rep/alpha`)
            .then(res => {
                let agents = auth.decryptData(res.data);

                setAgents(agents);
            }).catch(error => alert(error.message));

        config.DConnect().get(`admin/wapp/${auth.getUser()}/${auth.getToken()}/usertypes/3`)
            .then(res => {
                let resdata = auth.decryptData(res.data),
                    retailers = _.groupBy(resdata, 'username');

                setRetailers(retailers);
            }).catch(error => alert(error.message));
    }

    const handleChange = (e) => {
        e.preventDefault();

        const { name, value } = e.target;

        setFormItems(prevProps => ({ ...prevProps, [name]: value }));
    }

    const handleEvent = (event, picker) => {

        let fItems = { ...formItems };

        fItems['startDate'] = picker.startDate;
        fItems['endDate'] = picker.endDate;

        setFormItems(fItems);
    }

    const previewData = () => {
        if (formItems.rate == 0) {
            alert("Rate must be greater than zero(0)...");

            return;
        }

        setLoader(true);

        let formData = auth.encryptData(JSON.stringify({ ...formItems }));

        axios(config.PostOptions(formData, 'report/wapp/agentretailersale'))
            .then((res) => {
                if (isNaN(res.data)) {
                    let resdata = auth.decryptData(res.data),
                        rdata = _.groupBy(resdata, 'retailer'),
                        retailersale = {}, sum = { ...totals };

                    sum['total'] = _.sumBy(resdata, 'totalSales');

                    Object.keys(rdata).map(key => {
                        sum[key] = _.sumBy(rdata[key], 'totalSales');

                        retailersale[key] = _.groupBy(rdata[key], 'gamecode');
                    });

                    setComDetails(retailersale);
                    setTotals(sum);
                } else {
                    auth.doAlert(res.data);
                }

                setLoader(false);
            }).catch((error) => { alert(error); setLoader(false); });
    }

    const makePayment = async (agent) => {
        if (window.confirm("This will send money to Retailer(s) and/or Virtual user(s)... Do you want to continue?") == true) {
            let formData = [];


            if (agent === 0) {
                Object.keys(totals).map(async (key, idx) => {
                    if (key !== 'total') {
                        let amount = (parseFloat(totals[key]) * parseFloat(formItems.rate) / 100).toFixed(2);

                        formData.push({
                            'payload': {
                                'username': key,
                                'isdn': '',
                                'mobile': 'Bonus payment',
                                'voucher': auth.getUser(),
                                'amount': amount
                            }, 'log': {
                                'id': 0, 'username': key,
                                'rate': formItems.rate, 'totalSales': totals[`${key}`],
                                'bonus': amount, 'startDate': formItems.startDate,
                                'endDate': formItems.endDate,
                                'createdby': auth.getUser(),
                                'createdon': new Date(),
                                'curuser': auth.getUser(),
                                'token': auth.getToken()
                            }
                        });
                    }
                });
            } else {
                formData.push({
                    'payload': {
                        'username': agent.username,
                        'isdn': '',
                        'mobile': 'Bonus payment',
                        'voucher': auth.getUser(),
                        'amount': agent.amount
                    }, 'log': {
                        'id': 0, 'username': agent.username, 'rate': formItems.rate, 'totalSales': totals[`${agent.username}`],
                        'bonus': agent.amount, 'startDate': formItems.startDate, 'endDate': formItems.endDate,
                        'createdby': auth.getUser(), 'createdon': new Date(), 'curuser': auth.getUser(), 'token': auth.getToken()
                    }
                });
            }

            await agentPayment(formData);
        }
    }

    const agentPayment = async (fItems) => {
        setLoader(true);
        let formData = auth.encryptData(JSON.stringify(fItems));

        await axios(config.PostOptions(formData, 'admin/wapp/retailer/commission/payment'))
            .then((res) => {
                if (isNaN(res.data)) {
                    let resdata = auth.decryptText(res.data);

                    alert(JSON.stringify(resdata));
                } else {
                    auth.doAlert(parseInt(res.data))
                }

                setLoader(false);
            }).catch((error) => { alert(error); setLoader(false); })
    }

    const goBack = () => ReactDOM.render(<CommissionList />, document.getElementById('commissionCon'));

    if(loader){
        return <div id="loader"></div>
    }

    return (
        <div className="row">
            <div className="col-xs-12 text-center">
                <div>
                    <div className="btn-group btn-group-md" >
                        <button className="btn btn-default">Sales Date: </button>
                        <DatetimeRangePicker
                            startDate={formItems.startDate}
                            endDate={formItems.endDate}
                            ranges={ranges}
                            onApply={handleEvent}
                            className="selected-date-range-btn btn btn-default">
                            <div className="pull-left">
                                <i className="fa fa-calendar" />&nbsp; &nbsp;<span>{label}</span>
                            </div>
                            <div className="pull-right">&nbsp; &nbsp;<i className="fa fa-angle-down" /></div>
                        </DatetimeRangePicker>
                        <button className="btn btn-default">Game: </button>
                        <select id="game" name="game" className="btn btn-default form-control" style={{ width: '200px', height: '32.57px' }}
                            onChange={handleChange} value={formItems.game} >
                            <option value={0}>All Games</option>
                            {games.map(game => (
                                <option value={game.id}>{game.gamename}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <div className="btn-group btn-group-md" >
                        <button className="btn btn-default">Agent: </button>
                        <select id="username" name="username" className="btn btn-default form-control" style={{ width: '200px', height: '32.57px' }}
                            onChange={handleChange} value={formItems.username} >
                            <option value=''>All Agents</option>
                            {agents.map(agent => (
                                <option value={agent.username}>{agent.fullname}</option>
                            ))}
                        </select>
                        <button className="btn btn-default">Commission Rate (%): </button>
                        <input type="number" min="1" max="100" className="btn btn-default form-control text-right" style={{ width: '200px', height: '32.57px' }}
                            onChange={handleChange} value={formItems.rate} id="rate" name="rate" />
                    </div>
                </div>
                <div style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <div className="btn-group btn-group-md" >
                        <button className="btn btn-success" style={{ marginRight: '15px' }} onClick={previewData}> <i class="fa fa-eye"></i> Preview Sales</button>
                        {Object.keys(comDetails).length > 0 &&
                            <button className="btn btn-danger" style={{ marginRight: '15px' }} onClick={() => makePayment(0)}> <i class="fa fa-money"></i> Pay All</button>}
                        <button className="btn btn-warning" onClick={goBack}> <i class="fa fa-backward"></i> Go Back</button>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
                <hr className="hrsp" />
                <div className="row">
                    <div className="col-xs-6 col-md-4 col-lg-3">
                        Total Sales: <b>{numformat.format(totals.total)}</b>
                    </div>
                    <div className="col-xs-6 col-md-4 col-lg-3">
                        Total Commission: <b>{numformat.format(totals.total * parseFloat(formItems.rate) / 100)}</b>
                    </div>
                </div>
                <hr className="hrsp" />
                {Object.keys(comDetails).length > 0 &&
                    <table style={{ border: 'none' }} className="table table-condensed">
                        <thead>
                            <tr style={{ border: '1px solid gray' }}>
                                <th>Username</th>
                                <th>Retailer Name</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(comDetails).map(retailer =>
                                <tr>
                                    <td>{retailer}</td>
                                    <td>{(retailers[retailer] !== undefined) ? retailers[retailer][0].fullname : ''}</td>
                                    <td>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Game Name</th>
                                                    <th className="text-right">Gross Sales</th>
                                                    <th className="text-right">Rate (%)</th>
                                                    <th className="text-right">Total Commission</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(comDetails[retailer]).map(game =>
                                                    <tr>
                                                        <td>{comDetails[retailer][`${game}`][0].gamename}</td>
                                                        <td className="text-right">{numformat.format(_.sumBy(comDetails[retailer][`${game}`], 'totalSales'))}</td>
                                                        <td className="text-right">{numformat.format(formItems.rate)}</td>
                                                        <td className="text-right">
                                                            {numformat.format(_.sumBy(comDetails[retailer][`${game}`], 'totalSales') * parseFloat(formItems.rate / 100))}
                                                        </td>
                                                    </tr>)}
                                                <tr>
                                                    <td></td>
                                                    <td className="text-right"><b>{numformat.format(totals[retailer])}</b></td>
                                                    <td className="text-right">{''}</td>
                                                    <td className="text-right"><b>{numformat.format(totals[retailer] * parseFloat(formItems.rate) / 100)}</b></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td><button className="btn btn-danger btn-sm" onClick={() => makePayment({ 'username': retailer, 'amount': (totals[retailer] * parseFloat(formItems.rate) / 100).toFixed(2) })}> <i class="fa fa-money"></i> Pay </button></td>
                                </tr>)}
                        </tbody>
                    </table>}
            </div>
        </div>
    );
}

export default CommissionForm;