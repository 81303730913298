import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'

export default class EmailConfig extends Component{
    constructor(){
        super()
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems:{
                username:'', password:'', host:'', port: '',msgtype:'email'
            }
        }
    }
    handleChange=(e)=>{
     let formItems = this.state.formItems
     formItems[e.target.name] = e.target.value
     this.setState({ formItems }) 
    }
    componentDidMount(){
        this.getEmailsetup()
    }

    submit=(e)=>{
        e.preventDefault()
        let formData = this.auth.encryptData(JSON.stringify(this.state.formItems))
        axios(this.config.PostOptions(formData,'admin/wapp/emailsetup')).then((res) => {
            var resdata = this.auth.decryptText(res.data)
            alert((resdata=='')?"Email Setup successfully ":resdata );
            
        })
    }

    getEmailsetup = () =>{
        this.$http.get('admin/wapp/emailsetup')
        .then(res => {
            let formItems = this.auth.decryptData(res.data)
            this.setState({ formItems })
        }).catch((error)=>{console.log(error)})
    }
    render(){
       
        return(
            <div className="row">
                <article className="col-xs-12" style={{padding:'10px 20px 0 20px'}}>
                    <h3 className="pull-left">Email Setup</h3>
                    <div className="btn-group btn-group-sm pull-right clearfix">
                        <button type="submit" className="btn btn-success" onClick={this.submit} form="frmSetup">
                            <i className="glyphicon glyphicon-save"></i> Save
                        </button>
                    </div>
                </article>
                <article className="col-xs-12"><hr className="hrsp" /></article>
                <article className="col-xs-12 col-sm-10">
                    <form id="frmSetup" className="smart-form" onSubmit={this.formSubmit}>
                        <div className="row">
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Host Name: </label>
                                <section className="col col-8">
                                    <label className="input">
                                    <input type="text" name="host" autocomplete="off" placeholder="Host Name" value={this.state.formItems.host} onChange={this.handleChange} aria-required="true" aria-describedby="fname-error" />
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Port: </label>
                                <section className="col col-8">
                                    <label className="input">
                                    <input type="text" name="port" autocomplete="off" placeholder="Port Number" value={this.state.formItems.port} onChange={this.handleChange} aria-required="true" />
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Username: </label>
                                <section className="col col-8">
                                    <label className="input">
                                    <input type="email" name="username" autocomplete="off" placeholder="Username" value={this.state.formItems.username} onChange={this.handleChange} aria-required="true" aria-describedby="email-error"/>
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Password: </label>
                                <section className="col col-8">
                                    <label className="input">
                                    <input type="password" autocomplete="off" name="password" placeholder="Password" value={this.state.formItems.password}  onChange={this.handleChange} aria-required="true" aria-describedby="phone-error" />
                                    </label>
                                </section>
                            </div>
                           
                        </div>
                    </form>
                </article>
                <article className="col-xs-12 col-sm-2">
                    {/* <div id="custImgCon" className="table-bordered imgCon text-center unselectable" onClick={()=>this.imageClick('fileLogo')}>
                        <img src={this.config.ImagePath('custfiles') + '/' + this.state.formItems.logo} style={{width:'100'}} alt="Photo" />
                    </div>
                    <input type="file" name="logo" style={{display:'none'}} id="fileLogo" onChange={(e)=>this.imageChange(e,'custImgCon')} /> */}
                </article>
            </div>



        )
    }
}