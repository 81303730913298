import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Auth from "../../common/Auth";
import Config from "../../Config";
import { RetailerListReport } from "./ReportExport";
import RetailerPerAgent from "../RetailerPerAgent";

function RetailerListPdf(props) {
    const [rowData, setRowData] = useState(props?.rowData || []);
    const [data, setData] = useState(props.data);

    const auth = new Auth();
    const config = new Config();
    const $http = config.DConnect();

    const colstyle = { fontSize: "30px", display: "none" };
    const numformat = new Intl.NumberFormat("en-GB", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });

    useEffect(() => {
        setData(props.data);
        setRowData(props?.rowData || []);

        getCompany();
    }, [props.data, props.rowData]);

    const getCompany = () => {

        $http.get('admin/wapp/setup').then(res => {
            let company = auth.decryptData(res.data);

            showReport(company);
        })
    }

    const showReport = async (company) => {
        const pdf = new jsPDF("p", "mm", "a4", true);
        pdf.setFont("Helvetica")

        let props = {
            title: `${data?.agent} RETAILERS LIST REPORT`,
            subject: `${data?.agent} RETAILERS  LIST REPORT`,
            keywords: 'draw, screen, alpha, lotto',
            creator: 'Mojave Consultancy',
            company: company
        },

        pdfdata = await RetailerListReport(pdf, props, data, rowData);

        document.getElementById('pdfPreview').src = pdfdata;
    }

    const goBack = () => {
        ReactDOM.render(
            <RetailerPerAgent />,
            document.getElementById('generalReport_inners')
        );
    }

    return (
        <div className="row">
            <article className="col-xs-12">
                <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                    <header>
                        <span class="widget-icon"> <i class="fa fa-book"></i> </span>
                        <h2><strong>{data?.agent} Retailers List Report</strong> </h2>
                    </header>
                    <div>
                        <div className="jarviswidget-editbox"></div>
                        <div className="widget-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-10">
                                    <iframe id="pdfPreview" type="application/pdf" style={{ 'width': '100%', 'height': '550px', 'border': 'none' }}></iframe>
                                </div>
                                <div className="col-xs-12 col-sm-2">
                                    <div className="btn-group btn-group-vertical btn-group-sm">
                                        <button className="btn btn-warning" onClick={goBack}>
                                            <i className="fa fa-backward"></i> Back
                                        </button>
                                        {/* <button className="btn btn-success"> <i className="fa fa-file-excel-o"></i> Export Excel</button>
                                        <button className="btn btn-success"> <i className="fa fa-file-archive-o"></i> Export CSV</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    );
}

export default RetailerListPdf;