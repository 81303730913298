import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'
import ReactDOM from 'react-dom'

export default class StakeTicketDetails extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            loader: false,
            imgReceipt:'',
            ticketSerial: this.props.ticketSerial,
            isTicket: true
        }
    }

    componentDidMount(){
        this.getImageTicket(this.props.ticketSerial)
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            let ticketSerial = nextProps.ticketSerial

            this.setState({ ticketSerial })

            this.getImageTicket(ticketSerial)
        }
    }

    getImageTicket = (ticketSerial) => {
        ReactDOM.render( '', document.getElementById('saleDetailCon'))
        if(ticketSerial !== ''){
            if(isNaN(ticketSerial) || `${ticketSerial}`.length < 15){
                let url = `admin/wapp/${this.auth.getUser()}/${this.auth.getToken()}/unitsaleimage/${ticketSerial}`
                
                this.$http.get(url, {responseType: 'arraybuffer'})
                .then((res) => {
                    let imgReceipt = new Buffer(res.data, 'binary').toString('base64');
                    
                    //this.setState({ imgReceipt })

                    ReactDOM.render(
                        <img id="receiptImageCon" src={`data:image/jpeg;base64,${imgReceipt}`} style={{width:'600px'}} />,
                        document.getElementById('saleDetailCon')
                    )
                    
                }).catch((error)=>{
                    console.log("Image: " + error)
                })

                this.setState({ isTicket:false})
            }else{
                let url = 'admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/liveticketimage/' + ticketSerial
            
                this.$http.get(url, {responseType: 'arraybuffer'})
                .then((res) => {
                    let imgReceipt = new Buffer(res.data, 'binary').toString('base64')
                    
                    //this.setState({ imgReceipt })

                    ReactDOM.render(
                        <img id="receiptImageCon" src={`data:image/jpeg;base64,${imgReceipt}`} style={{width:'300px'}} />,
                        document.getElementById('saleDetailCon')
                    )
                    
                }).catch((error)=>{
                    console.log("Ticket Image: " + error)
                })

                this.setState({ isTicket:true})
            }
        }
    }

    loading = () => <div id="loader"></div>

    render(){
        if(this.state.loader){
            return this.loading()
        }else{
            return(
                <div className="modal fade" id="winTicketDetails" tabindex="-1" role="dialog" aria-hidden="true">  
                    <div className="modal-dialog" style={{width:(this.state.isTicket)?'350px':'650px'}}>  
                        <div className="modal-content"> 
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                                <h5 className="modal-title"><b>{(this.state.isTicket)? 'Ticket':'Unit'} Details</b></h5>
                            </div>
                            <div id="saleDetailCon" className="modal-body" ></div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}