import React, { Component } from 'react'
import { Link, BrowserRouter } from 'react-router-dom'
import 'bootstrap-daterangepicker/daterangepicker.css'
import moment from 'moment'
import QueryJson from '../common/QueryJson'
import axios from 'axios'
import Auth from '../common/Auth'
import Config from '../Config'
import _ from 'lodash'
import ReactDOM from 'react-dom'
import $ from 'jquery'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import ReportView from './ReportView';
import GameSalePdf from './Pdf/GameSalePdf';

const numformat = new Intl.NumberFormat("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 });

export default class GameSale extends Component {
    constructor(props) {
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems: {
                operator: '', username: '', game: 0, startDate: moment(),
                curuser: this.auth.getUser(), token: this.auth.getToken()
            },
            gamesale: { drawdate: new Date(), gamecode: 0, drawno: 0, tmodel: [] },
            totals: { totalGross: 0, totalNet: 0 },
            reportData: [],
            operators: [], operator: {}, agents: [], games: []
        }
    }

    componentDidMount() {
        this.setState({ loader: true })
        let systemDefine = (this.auth.getRole() == "Admin" || this.auth.getRole() == "Super") ? '/usertypes/1' : '/usertypesoperator/' + this.auth.getUser() + '/' + 1
        this.getOperators(systemDefine)
    }

    componentDidUpdate(nextProps) {
        if (this.props !== nextProps) {
            this.setState({ loader: true })
        }
    }

    getOperators = (systemDefine) => {
        if (systemDefine != undefined) {
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + systemDefine)
                .then(res => {
                    if (isNaN(res.data)) {
                        let operators = this.auth.decryptData(res.data)
                        let operator = (operators[0] != undefined) ? operators[0].username : operators.username

                        this.setState({ operator, operators })
                        this.getAgents(operator)
                    } else {
                        this.auth.doAlert(parseInt(res.data))
                    }

                })
        }
    }

    getAgents = (operator) => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/agentdetails_rep/' + operator)
            .then(res => {
                let agents = this.auth.decryptData(res.data)
                let formItems = this.state.formItems
                //let agents = resdata.filter(rdata => rdata.usertype == 2)
                formItems['username'] = (agents[0] != undefined) ? agents[0].username : ""
                this.setState({ agents, loader: false, formItems })
                this.getRetailer()
            })
    }

    getGame = (drawDate) => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/gamebydrawdate/' + encodeURIComponent(drawDate))
            .then(res => {
                let games = this.auth.decryptData(res.data)
                let formItems = this.state.formItems
                formItems['game'] = (games[0] != undefined) ? games[0].id : games.id
                this.setState({ games, loader: false })
            })
    }

    getRetailer = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertypes/3')
            .then(res => {
                if (isNaN(res.data)) {
                    let retailer = this.auth.decryptData(res.data)

                    this.setState({ retailer })
                } else {
                    this.auth.doAlert(parseInt(res.data))
                }
            })
    }

    getGamename = (code) => {
        let game = new QueryJson(this.state.games, 'id=' + code)
        return (game[0] != undefined) ? game[0].gamename : ""
    }

    agentName = (username) => {
        let agent = this.state.agents.find(agent => agent.username === username);
        return `${agent?.fullname} (${username})`;
    }

    retailerFomat = (cell, row) => {
        let retailer = new QueryJson(this.state.retailer, 'username=' + cell)
        return (retailer[0] != undefined) ? cell + ' - ' + retailer[0].fullname : cell
    }

    curFormat = (cell, row) => numformat.format(cell)

    getReport = () => {
        let formItems = this.state.formItems

        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'report/wapp/gamesale'))
            .then((res) => {
                if (isNaN(res.data)) {
                    let gamesale = this.auth.decryptData(res.data),
                        totals = this.state.totals,

                        reportData = _.map(_.groupBy(gamesale.tmodel, 'agent'), function (value, key) {
                            const gross = _.reduce(value, function (total, o) { return total += o.ticketTotal }, 0),
                                commission = gross * .2;

                            return {
                                'agent': key,
                                'gross': gross,
                                'commission': commission,
                                'net': gross - commission
                            }
                        });

                    totals['totalGross'] = _.reduce(reportData, function (total, o) { return total += o.gross }, 0);
                    totals['totalNet'] = _.reduce(reportData, function (total, o) { return total += o.net }, 0);

                    this.setState({ gamesale, reportData, totals });
                } else {
                    this.auth.doAlert(parseInt(res.data))
                }
            })
    }

    printPreview = () => {
        let formItems = this.state.formItems,
            gamesale = this.state.gamesale,
            reportData = this.state.reportData,

            data = {
                event: gamesale.drawno, gamename: this.getGamename(formItems.game),
                drawdate: gamesale.drawdate, agent: this.agentName(formItems.username)
            },

            rowData = reportData.map(rdata => {
                rdata['agent'] = this.agentName(rdata.agent);
                rdata['gross'] = rdata.gross;
                rdata['commission'] = rdata.commission;
                rdata['net'] = rdata.net;

                return rdata;
            })

        ReactDOM.render(
            <GameSalePdf data={data} rowData={rowData} />,
            document.getElementById('generalReport_inners')
        )
    }

    handleChange = (e) => {
        let ptype = e.target.value
        let fitm = this.state.formItems
        fitm[e.target.name] = ptype
        this.setState({ formItems: fitm })
        if (e.target.name == "operator") {
            this.getAgents(e.target.value)
        } else if (e.target.name == 'startDate') {
            this.getGame(ptype)
        }
    }

    goBack = () => {
        ReactDOM.render(<ReportView />, document.getElementById('generalReport_inners'))
    }

    loading = () => <div id="loader"></div>

    render() {
        if (this.state.loader) {
            return this.loading()
        } else {
            let asales = this.state.gamesale

            return (
                <div className="row">
                    <article className="col-xs-12">
                        <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                            <header>
                                <span class="widget-icon"> <i class="fa fa-book"></i> </span>
                                <h2><strong>Game Sales Report</strong> </h2>
                            </header>
                            <div>
                                <div className="widget-body">
                                    <div className='row'>
                                        <div className="col-xs-12">
                                            <div className="btn-group btn-group-sm">
                                                <button className="btn btn-default">Draw Date: </button>
                                                <input type="date" class="date" name="startDate" value={this.state.formItems.startDate} id="" className="btn btn-default" style={{ width: '200px', height: '30px' }} onChange={this.handleChange} />

                                                <button className="btn btn-default">Game: </button>

                                                <select id="" name="game" onChange={this.handleChange} value={this.state.formItems.game} className="btn btn-default" style={{ width: '200px', height: '30px' }}>
                                                    {this.state.games.map(gm =>
                                                        <option value={gm.id}>{gm.gamename}</option>
                                                    )}
                                                </select>
                                                <button onClick={this.getReport} className="btn btn-info">Display</button>
                                            </div>

                                            <div class="btn-group pull-right">
                                                <div className="btn-group btn-group-sm pull-right" >
                                                    <button className="btn btn-primary " style={{ borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }} onClick={this.printPreview}>Preview</button>
                                                </div>
                                                <div className="btn-group btn-group-sm pull-right" >
                                                    <button className="btn btn-warning " style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }} onClick={this.goBack}>Go Back</button>
                                                </div>
                                            </div>
                                            <hr className="hrsp" />
                                        </div>

                                        <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 bg-primary text-center" style={{ padding: "5px", fontSize: "18px", textTransform: "uppercase" }}>
                                            GAME SALES REPORT
                                        </div>
                                        <div className="col-xs-12"><hr className="hrsp" /></div>
                                        <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
                                            <table className="table table-condensed table-striped">
                                                <tbody>
                                                    <tr>
                                                        <td width="100px" className="text-right">Event Number: </td><td width="150px"><b>{asales.drawno}</b></td>
                                                        <td width="100px" className="text-right"></td><td width="150px" ><b></b></td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100px" className="text-right">Game Name: </td><td width="150px"><b>{this.getGamename(this.state.formItems.game)}</b></td>
                                                        <td width="100px" className="text-right">Total Gross: </td><td width="150px" ><b>{numformat.format(this.state.totals.totalGross)}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td width="100px" className="text-right">Draw Date: </td><td width="150px"><b>{moment(this.state.formItems.startDate).format('DD MMM, YYYY')}</b></td>
                                                        <td width="100px" className="text-right">Total Net: </td><td width="150px" ><b>{numformat.format(this.state.totals.totalNet)}</b></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                        <div className="col-xs-12"><hr className="hrsp" /></div>
                                        <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
                                            <BootstrapTable data={this.state.reportData} striped hover condensed pagination >
                                                <TableHeaderColumn dataField='agent' dataFormat={this.agentName} isKey dataSort>AGENT ID-NAME</TableHeaderColumn>
                                                <TableHeaderColumn dataField='gross' dataSort dataAlign="right" dataFormat={this.curFormat} width="120">GROSS</TableHeaderColumn>
                                                <TableHeaderColumn dataField='commission' dataAlign="right" dataFormat={this.curFormat} dataSort width="120">COMMISSION</TableHeaderColumn>
                                                <TableHeaderColumn dataField='net' dataAlign="right" dataFormat={this.curFormat} dataSort width="120">NET</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            )
        }
    }
}