import React, { Component } from 'react';
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import moment from 'moment'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import "react-datepicker/dist/react-datepicker.css";
import QueryJson from '../common/QueryJson'
import ReportView from './ReportView';
import _ from 'lodash'
import axios from 'axios'
import AgentSalePdf from './Pdf/AgentSalePdf';

const numformat = new Intl.NumberFormat("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 });

export default class AgentSale extends Component {
    constructor() {
        super()

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            operators: [], operator: {}, agents: [], game: [],
            formItems: {
                operator: '', username: '', game: '', startDate: '',
                curuser: this.auth.getUser(), token: this.auth.getToken()
            },
            agentsale: { drawdate: new Date(), gamecode: 0, drawno: 0, tmodel: [] },
            reportData: [],
            totals: { totalGross: 0, totalNet: 0 }
        }
    }

    componentDidMount() {
        this.setState({ loader: true })

        this.getRetailer()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({ loader: true })
            this.getRetailer()
        }
    }

    getOperators = () => {
        let systemDefine = (this.auth.getRole() == "Admin" || this.auth.getRole() == "Super") ? '/usertypes/1' : '/usertypesoperator/' + this.auth.getUser() + '/' + 1
        if (systemDefine != undefined) {
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + systemDefine)
                .then(res => {
                    if (isNaN(res.data)) {
                        let operators = this.auth.decryptData(res.data)
                        let operator = (operators[0] != undefined) ? operators[0].username : operators.username

                        this.setState({ operator, operators })
                        this.getAgents(operator)
                    } else {
                        this.auth.doAlert(parseInt(res.data))
                    }

                })
        }
    }

    getAgents = (operator) => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/agentdetails_rep/' + operator)
            .then(res => {
                let agents = this.auth.decryptData(res.data)
                let formItems = this.state.formItems
                //let agents = resdata.filter(rdata => rdata.usertype == 2)
                formItems['username'] = (agents[0] != undefined) ? agents[0].username : ""
                this.setState({ agents, loader: false, formItems })
            })
    }

    getGame = (drawDate) => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/gamebydrawdate/' + drawDate)
            .then(res => {
                let game = this.auth.decryptData(res.data)
                let formItems = this.state.formItems
                formItems['game'] = (game[0] != undefined) ? game[0].id : game.id

                this.setState({ game, loader: false })
            }).catch(error => alert(error));
    }

    getRetailer = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertypes/3')
            .then(res => {
                if (isNaN(res.data)) {
                    let retailer = this.auth.decryptData(res.data)

                    //alert(JSON.stringify(retailer))
                    this.setState({ retailer })

                    this.getOperators()
                } else {
                    this.auth.doAlert(parseInt(res.data))
                }
            })
    }

    getGamename = (code) => {
        let game = new QueryJson(this.state.game, 'id=' + code)
        return (game[0] != undefined) ? game[0].gamename : ""
    }

    agentName = (username) => {
        let agent = new QueryJson(this.state.agents, 'username=' + username)
        return (agent[0] != undefined) ? agent[0].fullname : ""
    }

    retailerFomat = (cell, row) => {
        let retailer = this.state.retailer.filter(ret => ret.username == cell)
        return (retailer[0] != undefined) ? cell + ' - ' + retailer[0].fullname : cell
    }

    getReport = () => {
        let formData = this.auth.encryptData(JSON.stringify(this.state.formItems));
        axios(this.config.PostOptions(formData, 'report/wapp/agentsale'))
            .then((res) => {
                if (isNaN(res.data)) {
                    let agentsale = this.auth.decryptData(res.data),
                        totals = this.state.totals,

                        groups = _.groupBy(agentsale.tmodel, 'retailer'),
                        sumData = _.map(groups, function (value, key) {
                            return {
                                retailer: key,
                                gross: _.reduce(value, function (total, o) { return total += o.ticketTotal }, 0)
                            }
                        }),

                        reportData = sumData.map(rdata => {
                            let commission = rdata.gross * .2
                            rdata['commission'] = commission
                            rdata['net'] = rdata.gross - commission

                            return rdata
                        });

                    // alert(JSON.stringify(agentsale));

                    totals['totalGross'] = _.reduce(reportData, function (total, o) { return total += o.gross }, 0)
                    totals['totalNet'] = _.reduce(reportData, function (total, o) { return total += o.net }, 0)

                    this.setState({ agentsale, reportData, totals })
                } else {
                    this.auth.doAlert(parseInt(res.data));
                }
            })
    }

    goBack = () => {
        ReactDOM.render(<ReportView />, document.getElementById('generalReport_inners'))
    }

    curFormat = (cell, row) => numformat.format(cell);

    printPreview = () => {
        let formItems = this.state.formItems,
            agentsale = this.state.agentsale,
            reportData = this.state.reportData,
            data = {
                event: agentsale.drawno, gamename: this.getGamename(formItems.game),
                drawdate: agentsale.drawdate, agent: this.agentName(formItems.username)
            },
            rowData = reportData.map(rdata => {
                rdata['retailer'] = this.retailerFomat(rdata.retailer, rdata);
                rdata['gross'] = rdata.gross;
                rdata['commission'] = rdata.commission;
                rdata['net'] = rdata.net;

                return rdata;
            });

        ReactDOM.render(
            <AgentSalePdf data={data} rowData={rowData} />,
            document.getElementById('generalReport_inners')
        )
    }

    handleChange = (e) => {
        let ptype = e.target.value
        let fitm = this.state.formItems
        fitm[e.target.name] = ptype
        this.setState({ formItems: fitm })
        if (e.target.name == "operator") {
            this.getAgents(e.target.value)
        } else if (e.target.name == 'startDate') {
            this.getGame(ptype)
        }
    }

    loading = () => <div id="loader"></div>

    render() {

        if (this.state.loader) {
            return this.loading();
        } else {
            let asales = this.state.agentsale;
            return (
                <div className="row">
                    <article className="col-xs-12">
                        <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                            <header>
                                <span class="widget-icon"> <i class="fa fa-book"></i> </span>
                                <h2><strong>Agent Sales</strong> </h2>
                            </header>
                            <div>
                                <div className="row">
                                    <div className="col-xs-12 ">
                                        <div className="btn-group btn-group-sm">
                                            <button className="btn btn-default">Operator: </button>
                                            <select id="" name="operator" onChange={this.handleChange}
                                                value={this.state.formItems.operator} className="btn btn-default"
                                                style={{ width: '200px', height: '30px' }}>
                                                {this.state.operators.map(agt =>
                                                    <option value={agt.username}>{agt.fullname}</option>)}
                                            </select>

                                            <button className="btn btn-default">Agent: </button>
                                            <select id="username" name="username" onChange={this.handleChange}
                                                value={this.state.formItems.username} className="btn btn-default"
                                                style={{ width: '200px', height: '30px' }}>
                                                {this.state.agents.map(agt =>
                                                    <option value={agt.username}>{agt.fullname}</option>
                                                )}
                                            </select>
                                        </div>

                                        <div className="btn-group btn-group-sm pull-right">
                                            <button className="btn btn-warning " id="back" onClick={this.goBack}>Go Back</button>
                                            <button className="btn btn-primary " onClick={this.printPreview}>Preview</button>
                                        </div>
                                        <hr className="hrsp" />
                                    </div>
                                    <div className="col-xs-12 ">
                                        <div className="btn-group btn-group-sm ">
                                            <button className="btn btn-default">Draw Date: </button>
                                            <input type="date" class="date" name="startDate" value={this.state.formItems.startDate} id="" className="btn btn-default" style={{ width: '200px', height: '30px' }} onChange={this.handleChange} />

                                            <button className="btn btn-default">Game: </button>

                                            <select id="" name="game" onChange={this.handleChange} value={this.state.formItems.game} className="btn btn-default" style={{ width: '200px', height: '30px' }}>
                                                {this.state.game.map(gm =>
                                                    <option value={gm.id}>{gm.gamename}</option>
                                                )}
                                            </select>
                                            <button onClick={this.getReport} className="btn btn-info">Display</button>
                                        </div>

                                    </div>
                                    <div className="col-xs-12"><hr className="hrsp" /></div>

                                    <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 bg-primary text-center" style={{ padding: "5px", fontSize: "18px", textTransform: "uppercase" }}>
                                        {this.agentName(this.state.formItems.username)} SALES REPORT
                                    </div>
                                    <div className="col-xs-12"><hr className="hrsp" /></div>
                                    <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
                                        <table className="table table-condensed table-striped">
                                            <tbody>
                                                <tr>
                                                    <td width="100px" className="text-right">Event Number: </td><td width="150px"><b>{asales.drawno}</b></td>
                                                    <td width="100px" className="text-right"></td><td width="150px" ><b></b></td>
                                                </tr>
                                                <tr>
                                                    <td width="100px" className="text-right">Game Name: </td><td width="150px"><b>{this.getGamename(this.state.formItems.game)}</b></td>
                                                    <td width="100px" className="text-right">Total Gross: </td><td width="150px" ><b>{numformat.format(this.state.totals.totalGross)}</b></td>
                                                </tr>
                                                <tr>
                                                    <td width="100px" className="text-right">Draw Date: </td><td width="150px"><b>{moment(this.state.formItems.startDate).format('DD MMM, YYYY')}</b></td>
                                                    <td width="100px" className="text-right">Total Net: </td><td width="150px" ><b>{numformat.format(this.state.totals.totalNet)}</b></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="col-xs-12"><hr className="hrsp" /></div>
                                    <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
                                        <BootstrapTable data={this.state.reportData} striped hover condensed pagination >
                                            <TableHeaderColumn dataField='retailer' dataFormat={this.retailerFomat} isKey dataSort>RETAILER ID-NAME</TableHeaderColumn>
                                            <TableHeaderColumn dataField='gross' dataSort dataAlign="right" dataFormat={this.curFormat} width="120">GROSS</TableHeaderColumn>
                                            <TableHeaderColumn dataField='commission' dataAlign="right" dataFormat={this.curFormat} dataSort width="140">C'SION (20%)</TableHeaderColumn>
                                            <TableHeaderColumn dataField='net' dataAlign="right" dataFormat={this.curFormat} dataSort width="120">NET</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </article>
                </div>
            )
        }
    }
}
