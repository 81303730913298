import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom'
import jsPDF from 'jspdf';
import "jspdf-autotable";
import Auth from '../../common/Auth'
import Config from '../../Config'
import { DoReport } from './ReportExport'
import moment from 'moment';
import _ from 'lodash';
import Commission from '../Commission';

function CommissionPdf(props) {

    const [rowData, setRowData] = useState(props.rowData);
    const [data, setData] = useState(props.data);

    const auth = new Auth();
    const config = new Config();
    const $http = config.DConnect();

    const colstyle = { fontSize: '30px', display: 'none' };
    const numformat = new Intl.NumberFormat("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 });

    useEffect(() => {

        setData(props.data);
        setRowData(props.rowData);

        getCompany();
    }, [props.data, props.rowData]);

    const getCompany = () => {

        $http.get('admin/wapp/setup').then(res => {
            let company = auth.decryptData(res.data);

            showReport(company);
        })
    }

    const showReport = (company) => {
        const pdf = new jsPDF("p", "mm", "a4", true);

        let props = {
            title: `AGENT COMMISSION REPORT`,
            subject: `AGENT COMMISSION REPORT`,
            keywords: 'draw, screen, alpha, lotto',
            creator: 'Mojave Consultancy',
            company: company
        },

            mainTable = {
                html: '#tbMaintable', startY: (50), theme: 'grid', styles: { fontSize: 8, showHead: 'everyPage' }, margin: { left: 5 },
                headStyles: { fillColor: '#efefef', textColor: '#000000', lineColor: '#000000', lineWidth: .1 },
                columnStyles: {
                    0: { cellWidth: 65 },
                    1: { cellWidth: 30, halign: 'right' },
                    2: { cellWidth: 30, halign: 'right' },
                    3: { cellWidth: 25, halign: 'right' },
                    4: { cellWidth: 25, halign: 'right' },
                    5: { cellWidth: 25, halign: 'right' }
                }
            },

            edata = {
                'Operator': data.operator,
                '': '',
                'Start Date': moment(data.startDate).format('DD MMM, YYYY'),
                'End Date': moment(data.endDate).format('DD MMM, YYYY')
            },

            pdfdata = DoReport(pdf, props, mainTable, edata);

        document.getElementById('pdfPreview').src = pdfdata;
    }

    const goBack = () => {
        ReactDOM.render(
            <Commission />,
            document.getElementById('generalReport_inners')
        );
    }

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-10">
                <iframe id="pdfPreview" type="application/pdf" style={{ 'width': '100%', 'height': '80vh', 'border': 'none' }}></iframe>
            </div>
            <div className="col-xs-12 col-sm-2">
                <div className="btn-group btn-group-vertical btn-group-sm">
                    <button className="btn btn-warning" onClick={goBack}>
                        <i className="fa fa-backward"></i> Back
                    </button>
                    <button className="btn btn-success"> <i className="fa fa-file-excel-o"></i> Export Excel</button>
                    <button className="btn btn-success"> <i className="fa fa-file-archive-o"></i> Export CSV</button>
                </div>
            </div>
            <div className="col-xs-12">
                <table id='tbMaintable' style={colstyle}>
                    {rowData}
                </table>
            </div>
        </div>
    );
}

export default CommissionPdf;
