import React, { useEffect, useState } from 'react';
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import ReactDOM from 'react-dom';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import CommissionDetails from './CommissionDetails';
import CommissionForm from './CommissionForm';
import Auth from '../common/Auth';
import Config from '../Config';
import axios from "axios";
import _ from "lodash";
import AgentBonusPdf from '../reports/Pdf/AgentBonusPdf';

function CommissionList(props) {

    const auth = new Auth();
    const config = new Config();
    const [games, setGames] = useState([]);
    const [agents, setAgents] = useState([]);
    const [users, setUsers] = useState([]);
    const [retailers, setRetailers] = useState({});

    const [reportData, setReportData] = useState([]);
    const [formItems, setFormItems] = useState({
        'startDate': moment().startOf('month'), 'endDate': moment().endOf('month'),
        curuser: auth.getUser(), token: auth.getToken()
    });

    const numformat = new Intl.NumberFormat("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    const ranges = {
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    }
    let start = formItems.startDate.format('MMM, YYYY'),
        end = formItems.endDate.format('MMM, YYYY'),
        label = (start === end) ? start : start + ' - ' + end;

    useEffect(() => {

        getInitData();

    }, []);

    const getInitData = () => {
        //config.DConnect()
        config.DConnect().get(`game/wapp/${auth.getUser()}/${auth.getToken()}/game`)
            .then(res => {
                let games = auth.decryptData(res.data);

                setGames(games);
            }).catch(error => alert(error.message));

        config.DConnect().get(`admin/wapp/${auth.getUser()}/${auth.getToken()}/agentdetails_rep/alpha`)
            .then(res => {
                let agents = auth.decryptData(res.data);

                setAgents(agents);
            }).catch(error => alert(error.message));

        config.DConnect().get(`admin/wapp/${auth.getUser()}/${auth.getToken()}/user`)
            .then(res => {
                let users = auth.decryptData(res.data);

                setUsers(users);
            }).catch(error => alert(error.message));

        config.DConnect().get(`admin/wapp/${auth.getUser()}/${auth.getToken()}/usertypes/3`)
            .then(res => {
                let resdata = auth.decryptData(res.data),
                    retailers = _.groupBy(resdata, 'username');

                setRetailers(retailers);
            }).catch(error => alert(error.message));
    }

    const handleEvent = (event, picker) => {

        let fItems = { ...formItems };

        fItems['startDate'] = picker.startDate;
        fItems['endDate'] = picker.endDate;

        setFormItems(fItems);
    }

    const getReportData = () => {
        let formData = auth.encryptData(JSON.stringify({ ...formItems }));

        axios(config.PostOptions(formData, 'report/wapp/bonus'))
            .then((res) => {
                if (isNaN(res.data)) {
                    let reportData = auth.decryptData(res.data);

                    setReportData(reportData);
                } else {
                    auth.doAlert(res.data);
                }
            }).catch(error => alert(error.message));
    }

    const showCommissionForm = () => ReactDOM.render(<CommissionForm agents={agents} retailers={retailers} games={games} />, document.getElementById('commissionCon'));

    const showCommissionDetails = () => ReactDOM.render(<CommissionDetails />, document.getElementById('commissionCon'));

    const formatPeriod = (cell, row) => `${moment(cell).format('DD MMM, YYYY')} - ${moment(row.endDate).format('DD MMM, YYYY')}`;

    const formatWriter = (cell, row) => (retailers[cell] !== undefined) ? retailers[cell][0].fullname : cell;

    const formatDate = (cell, row) => moment(cell).format('DD MMM YYYY');

    const formatCur = (cell, row) => numformat.format(cell);

    const formatUser = (cell, row) => {
        let uname = users.filter(user => user.username === cell);

        return (uname.length > 0) ? `${uname[0].firstname} ${uname[0].lastname}` : cell;
    }

    const printPreview = () => {
        ReactDOM.render(
            <AgentBonusPdf agents={reportData} filter={formItems}
                formatPeriod={formatPeriod} formatWriter={formatWriter}
                formatDate={formatDate} formatCur={formatCur} formatUser={formatUser} />,
            document.getElementById('commissionCon')
        )
    }

    return (
        <div className="row">
            <div className="col-xs-12 clearfix">
                <div className='text-center'>
                    <div className="btn-group btn-group-sm" >
                        <button className="btn btn-default">Sales Date: </button>
                        <DatetimeRangePicker
                            startDate={formItems.startDate}
                            endDate={formItems.endDate}
                            ranges={ranges}
                            onApply={handleEvent}
                            className="selected-date-range-btn btn btn-default">
                            <div className="pull-left">
                                <i className="fa fa-calendar" />&nbsp; &nbsp;<span>{label}</span>
                            </div>
                            <div className="pull-right">&nbsp; &nbsp;<i className="fa fa-angle-down" /></div>
                        </DatetimeRangePicker>

                    </div>
                </div>
                <div className='text-center' style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <div className="btn-group btn-group-md" >
                        <button className="btn btn-primary" style={{ marginRight: '15px' }} onClick={getReportData}> <i class="fa fa-eye"></i> Display</button>
                        <button className="btn btn-success" onClick={showCommissionForm} style={{ marginRight: '15px' }}> <i class="fa fa-plus"></i> New Sales Bonus</button>
                        <button className="btn btn-primary" onClick={printPreview}> <i class="fa fa-print"></i> Print Report</button>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 ">
                <hr className="hrsp" />
                <div className='responsive'>
                    <BootstrapTable data={reportData} striped hover condensed pagination search>
                        <TableHeaderColumn dataField='startDate' isKey dataSort dataFormat={formatPeriod} width='200px'>Period</TableHeaderColumn>
                        <TableHeaderColumn dataField='username' dataSort dataFormat={formatWriter} width='180px'>Writer</TableHeaderColumn>
                        <TableHeaderColumn dataField='createdon' dataSort dataFormat={formatDate} width='150px'>Processed On</TableHeaderColumn>
                        <TableHeaderColumn dataField='createdby' dataSort dataFormat={formatUser} width='180px'>Processed By</TableHeaderColumn>
                        <TableHeaderColumn dataField='totalSales' dataSort dataFormat={formatCur} width='150px' dataAlign="right">Gross Sales</TableHeaderColumn>
                        <TableHeaderColumn dataField='rate' dataSort dataFormat={formatCur} width='150px' dataAlign="right">Bonus Rate (%)</TableHeaderColumn>
                        <TableHeaderColumn dataField='bonus' dataSort dataFormat={formatCur} width='150px' dataAlign="right">Total Bonus</TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        </div>
    );
}

export default CommissionList;