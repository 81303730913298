import React from "react";
import logo from '../../alpha_logo.png';
import moment from "moment";
import Auth from "../../common/Auth";
import Config from "../../Config";

const ReportExport = () => { }

const auth = new Auth();
const config = new Config();

const numformat = new Intl.NumberFormat("en-GB", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});

var FileType = {
    'jpg': 'JPEG', 'jpeg': 'JPEG', 'png': 'PNG',
}

const SetCompany = (pdf, props, extraInof, pageWidth) => {

    pdf.setProperties(props);

    pdf.addImage(logo, 'png', 5, 5, 16, 16);

    pdf.setFontSize(14); pdf.setFont("helvetica", "bold");
    pdf.text(props.company.companyName, 25, 10);
    pdf.setFontSize(10); pdf.setFont("helvetica", "normal");
    pdf.text(props.company.address, 25, 16);
    pdf.text(`Tel: ${props.company.phone}`, 25, 21);

    pdf.text(`Printed by: ${auth.getUser()}`, pageWidth - 50, 16);
    pdf.text(`Printed on: ${moment().format('DD MMM, YYYY')}`, pageWidth - 50, 21);
    pdf.line(5, 22, pageWidth - 5, 22);

    pdf.setFontSize(14); pdf.setFont("helvetica", "bold");
    pdf.text(`${props.title}`, pageWidth / 2, 30, 'center');

    pdf.setFontSize(11); pdf.setFont("helvetica", "normal");
    let startPoint = 0, height = 40;

    if (extraInof) {
        Object.keys(extraInof).map((info, idx) => {
            startPoint = (idx + 1) % 2 === 1 ? 5 : (pageWidth - 10) / 2;

            if (idx > 0 && (idx + 1) % 2 === 1) {
                height += 5;
            }

            pdf.text(`${info}: ${extraInof[`${info}`]}`, startPoint, height);
        });

        if (Object.keys(extraInof).length > 0) {
            pdf.line(5, height + 2, pageWidth - 5, height + 2);
        }
    } else {
        pdf.line(5, height - 3, pageWidth - 5, height - 3);
    }

    return height;
}

const DoReport = (pdf, props, mainTable, extraInof) => {
    var pageWidth = pdf.internal.pageSize.getWidth();

    SetCompany(pdf, props, extraInof, pageWidth);

    pdf.autoTable(mainTable);

    return pdf.output('datauristring');
}

const loadImage = (url) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;

        img.onload = () => {
            resolve(img);
        };

        img.onerror = () => {
            resolve(img);
        };
    });
};

const AgentListReport = async (pdf, props, data, rowData) => {

    var pageWidth = pdf.internal.pageSize.getWidth(),
        pageHeight = pdf.internal.pageSize.getHeight(),
        height = SetCompany(pdf, props, null, pageWidth);

    pdf.setLineWidth(0.1);


    // rowData.map(async (rdata, idx) => {
    for(const rdata of rowData){
        if (height + 33 > pageHeight) {
            pdf.addPage();
            height = 5;
        }

        pdf.roundedRect(5, height, pageWidth - 10, 30, 1, 1);

        //Add agent Image
        const img = await loadImage(`${config.ImagePath('custfiles')}/${rdata.username}.${rdata.filex}`);
        try{ pdf.addImage(img, rdata.filex, 6, height + 1, 23, 28); }catch(error){}

        //Add Text
        let col2 = (pageWidth - 40) / 2
        pdf.text("Agent name: ", 35, height + 8);
        pdf.text("Agent ID: ", 35, height + 16);
        pdf.text("Location: ", 35, height + 24);
        pdf.text("Phone No.: ", 35 + col2, height + 16);
        pdf.text("Total Retailers: ", 35 + col2, height + 24);

        pdf.setFont("Helvetica", 'bold');
        pdf.text(`${rdata.fullname}`, 63, height + 8);
        pdf.text(`${rdata.username}`, 63, height + 16);
        pdf.text(`${rdata.location}`, 63, height + 24);
        pdf.text(`${rdata.mobile}`, 63 + col2, height + 16);
        pdf.text(`${rdata.retailers}`, 63 + col2, height + 24);

        pdf.setFont("Helvetica", 'normal');

        height += 33;
    };

    return pdf.output('datauristring');
}

const RetailerListReport = async (pdf, props, data, rowData) => {

    var pageWidth = pdf.internal.pageSize.getWidth(),
        pageHeight = pdf.internal.pageSize.getHeight(),
        height = SetCompany(pdf, props, null, pageWidth);

    pdf.setLineWidth(0.1);


    // rowData.map(async (rdata, idx) => {
    for(const rdata of rowData){
        if (height + 33 > pageHeight) {
            pdf.addPage();
            height = 5;
        }

        pdf.roundedRect(5, height, pageWidth - 10, 30, 1, 1);

        //Add agent Image
        const img = await loadImage(`${config.ImagePath('custfiles')}/${rdata.username}.${rdata.filex}`);
        try{ pdf.addImage(img, rdata.filex, 6, height + 1, 23, 28); }catch(error){}

        //Add Text
        let col2 = (pageWidth - 40) / 2
        pdf.text("Retailer name: ", 35, height + 8);
        pdf.text("Retailer ID: ", 35, height + 14);
        pdf.text("Location: ", 35, height + 20);
        pdf.text("Phone No.: ", 35, height + 26);
        pdf.text("Cash Bal.: ", 35 + col2, height + 14);
        pdf.text("Unit Bal.: ", 35 + col2, height + 20);
        pdf.text("Terminal: ", 35 + col2, height + 26);

        pdf.setFont("Helvetica", 'bold');
        pdf.text(`${rdata.fullname}`, 63, height + 8);
        pdf.text(`${rdata.username}`, 63, height + 14);
        pdf.text(`${rdata.location}`, 63, height + 20);
        pdf.text(`${rdata.mobile}`, 63, height + 26);
        pdf.text(`GHc ${numformat.format(rdata.cashinhand)}`, 63 + col2, height + 14);
        pdf.text(`${numformat.format(rdata.unit)}`, 63 + col2, height + 20);
        pdf.text(`${rdata.imei}`, 63 + col2, height + 26);

        pdf.setFont("Helvetica", 'normal');

        height += 33;
    };

    return pdf.output('datauristring');
}

const ExportTableToExcel = (tableID, filename = '') => {
    var downloadLink,
        dataType = 'application/vnd.ms-excel',
        tableSelect = document.getElementById(tableID),
        tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

    filename = filename ? filename + '.xls' : 'excel_data.xls';
    downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(['\ufeff', tableHTML], {
            type: dataType
        });
        navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
        downloadLink.download = filename;
        downloadLink.click();
    }
}

const Download_csv = (csv, filename) => {
    var csvFile, downloadLink;

    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
}

const ExportTableToCsv = (id, filename) => {
    var csv = [], rows = document.querySelectorAll(`table#${id} tr`);

    for (var i = 0; i < rows.length; i++) {
        var row = [], cols = rows[i].querySelectorAll("td, th");

        for (var j = 0; j < cols.length; j++)
            row.push(cols[j].innerText);

        csv.push(row.join(","));
    }

    Download_csv(csv.join("\n"), filename);
}

export default ReportExport;
export {
    ReportExport, DoReport, ExportTableToExcel, Download_csv,
    ExportTableToCsv, SetCompany, AgentListReport, RetailerListReport
}
