import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom'
import jsPDF from 'jspdf';
import "jspdf-autotable";
import Auth from '../../common/Auth'
import Config from '../../Config'
import RetailerSale from '../RetailerSale';
import { DoReport, SetCompany } from './ReportExport'
import moment from 'moment';
import _ from 'lodash';

function RetailerSalesPdf(props) {

    const [rowData, setRowData] = useState(props.rowData);
    const [data, setData] = useState(props.data);

    const auth = new Auth();
    const config = new Config();
    const $http = config.DConnect();

    const colstyle = { fontSize: '30px', display: 'none' };
    const numformat = new Intl.NumberFormat("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 });

    useEffect(() => {

        setData(props.data);
        setRowData(props.rowData);

        getCompany();
    }, [props.data, props.rowData]);

    const getCompany = () => {

        $http.get('admin/wapp/setup').then(res => {
            let company = auth.decryptData(res.data);

            showReport(company);
        })
    }

    const showReport = (company) => {
        const pdf = new jsPDF("p", "mm", "a4", true);

        let props = {
            title: `RETAILER SALES REPORT 20%`,
            subject: `RETAILER SALES REPORT 20%`,
            keywords: 'draw, screen, alpha, lotto',
            creator: 'Mojave Consultancy',
            company: company
        },

            mainTable = {
                html: '#tbMaintable', startY: (40 + 5 * 1), theme: 'grid', styles: { fontSize: 8, showHead: 'everyPage' }, margin: { left: 5 },
                headStyles: { fillColor: '#efefef', textColor: '#000000', lineColor: '#000000', lineWidth: .1 },
                columnStyles: {
                    0: { cellWidth: 95 },
                    1: { cellWidth: 35, halign: 'right' },
                    2: { cellWidth: 35, halign: 'right' },
                    3: { cellWidth: 35, halign: 'right' }
                }
            },

            edata = {
                'Retailer': data.agent,
                'Draw Date': moment(data.drawdate).format('DD MMM, YYYY')
            },

            pdfdata = DoReport(pdf, props, mainTable, edata);

        document.getElementById('pdfPreview').src = pdfdata;
    }

    const goBack = () => {
        ReactDOM.render(
            <RetailerSale />,
            document.getElementById('generalReport_inners')
        );
    }

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-10">
                <iframe id="pdfPreview" type="application/pdf" style={{ 'width': '100%', 'height': '80vh', 'border': 'none' }}></iframe>
            </div>
            <div className="col-xs-12 col-sm-2">
                <div className="btn-group btn-group-vertical btn-group-sm">
                    <button className="btn btn-warning" onClick={goBack}>
                        <i className="fa fa-backward"></i> Back
                    </button>
                    <button className="btn btn-success"> <i className="fa fa-file-excel-o"></i> Export Excel</button>
                    <button className="btn btn-success"> <i className="fa fa-file-archive-o"></i> Export CSV</button>
                </div>
            </div>
            <div className="col-xs-12">
                <table id='tbMaintable' style={colstyle}>
                    <thead>
                        <tr>
                            <th>Game</th>
                            <th>Gross</th>
                            <th>Commission</th>
                            <th>Net</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rowData.map(rdata =>
                            <tr>
                                <td>{rdata.gamename}</td>
                                <td>{numformat.format(rdata.gross)}</td>
                                <td>{numformat.format(rdata.commission)}</td>
                                <td>{numformat.format(rdata.net)}</td>
                            </tr>)}
                        <tr>
                            <th></th>
                            <th>{_.sumBy(rowData, 'gross').toFixed(2)}</th>
                            <th>{_.sumBy(rowData, 'commission').toFixed(2)}</th>
                            <th>{_.sumBy(rowData, 'net').toFixed(2)}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default RetailerSalesPdf;
