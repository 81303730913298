import React, { Component } from 'react'
import { Link, BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import * as jsPDF from 'jspdf'
import QueryJson from '../../common/QueryJson'
import Auth from '../../common/Auth'
import Config from '../../Config'
import base64Img from 'base64-img'
import moment from 'moment'
import AgentSummary from '../AgentSummary';
import Commission from '../Commission'
import CommissionList from '../../commission/CommissionList'

const colstyle = { fontSize: '30px', display: 'none' }

function showReport(img, row, data, comp) {
    const pdf = new jsPDF("l", "mm", "a4")

    pdf.setProperties({
        title: 'Agent Commission Report ',
        subject: ' Agent Commission Report ',
        author: comp.companyName,
        keywords: 'draw, screen, alpha, lotto',
        creator: 'Mojave Consultancy'
    })

    pdf.autoTable({
        html: '#tbCompany',
        theme: 'plain',
        styles: { fontSize: 18, fontStyle: 'bold', overflow: 'linebreak' },
        columnStyles: {
            0: { cellWidth: 15 },
            1: { halign: 'center' },
            2: { halign: 'right', valign: 'bottom', cellWidth: 30, fontSize: 8, fontStyle: 'normal' }
        }
    })

    if (img != '' && img != undefined) {
        let tp = 'PNG'
        if (img.indexOf('jpeg') != -1 && img.indexOf('jpg') != -1) {
            tp = 'JPEG'
        }
        pdf.addImage(img, tp, 15, 10, 0, 23)
    }
    pdf.line(14, 40, 282, 40)

    pdf.autoTable({
        html: '#tbContent',
        theme: 'striped',
        styles: { fontSize: 11 },
        columnStyles: {
            0: { cellWidth: 65 },
            1: { cellWidth: 35 },
            2: { cellWidth: 35 },
            3: { cellWidth: 35 },
            4: { halign: 'right', cellWidth: 32 },
            5: { halign: 'right', cellWidth: 32 },
            6: { halign: 'right', cellWidth: 32 }
        }
    })

    var data = pdf.output('datauristring')
    document.getElementById('pdfPreview').src = data
}
function exportTableToExcel(tableID, filename = '') {
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';
    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
    filename = filename ? filename + '.xls' : 'excel_data.xls';
    downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(['\ufeff', tableHTML], {
            type: dataType
        });
        navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
        downloadLink.download = filename;
        downloadLink.click();
    }
}
function download_csv(csv, filename) {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
}
function exportTableToCsv(tableID, filename = '') {
    var csv = [];
    var rows = document.querySelectorAll("table tr");
    for (var i = 0; i < rows.length; i++) {
        var row = [], cols = rows[i].querySelectorAll("td, th");
        for (var j = 0; j < cols.length; j++)
            row.push(cols[j].innerText);
        csv.push(row.join(","));
    }
    download_csv(csv.join("\n"), filename);
}
export default class AgentBonusPdf extends Component {
    constructor(props) {
        super(props)

        this.config = new Config()
        this.auth = new Auth()
        this.$http = this.config.DConnect()

        this.state = {
            rowData: this.props.rowData,
            data: this.props.data,
            companySetup: '',
            url: ''
        }
    }

    componentDidMount() {
        this.generatePDF()
    }

    componentDidUpdate(nextProps) {
        if (nextProps.rowData !== this.props.rowData) {
            let rowData = nextProps.rowData
            let data = nextProps.data

            this.setState({ rowData, data })
            this.generatePDF()
        }
    }

    generatePDF = () => {
        this.$http.get('admin/wapp/setup')
            .then(res => {
                let companySetup = this.auth.decryptData(res.data)

                this.setState({ companySetup })

                let rowData = this.state.rowData
                let data = this.state.data
                let url = 'admin/wapp/image/custfiles/' + companySetup.logo
                this.$http.get(url, { responseType: 'arraybuffer' })
                    .then((res) => {
                        let img = new Buffer(res.data, 'binary').toString('base64')
                        showReport(img, rowData, data, companySetup)
                    }).catch((error) => { showReport('', rowData, data, companySetup) })
                this.setState({ url })
            })
    }

    goBack = () => {
        ReactDOM.render(
            <CommissionList />,
            document.getElementById('commissionCon')
        )
    }

    exportTableToExcels = () => {

        exportTableToExcel('datas', 'Writer-Sales-Commission')
    }
    exportTableToCsv = () => {
        exportTableToCsv('datas', 'Writer-Sales-Commission')
    }
    render() {
        //alert(this.state.url)
        return (
            <div className="row">
                <article className="col-xs-12">

                    <div className="jarviswidget-editbox"></div>
                    <div className="widget-body">
                        <div className="row">
                            <div className="col-xs-12 col-sm-10">
                                <iframe id="pdfPreview" type="application/pdf" style={{ 'width': '100%', 'height': '550px', 'border': 'none' }}></iframe>
                            </div>
                            <div className="col-xs-12 col-sm-2">
                                <div className="btn-group btn-group-vertical btn-group-sm">
                                    <button className="btn btn-warning" onClick={this.goBack}>
                                        <i className="fa fa-backward"></i> Back
                                    </button>
                                    <button className="btn btn-success" onClick={this.exportTableToExcels}> <i className="fa fa-file-excel-o"></i> Export Excel</button>
                                    <button className="btn btn-success" onClick={this.exportTableToCsv}> <i className="fa fa-file-archive-o"></i> Export CSV</button>
                                </div>
                            </div>
                            <div className="col-xs-12" id="datas">
                                <table id="tbCompany" className="table table-condensed" style={colstyle}>
                                    <tbody>
                                        <tr>
                                            <td rowSpan="3">&nbsp;<img src={this.config.BaseURL() + this.state.url} /></td>
                                            <td>{this.state.companySetup.companyName}</td>
                                            <td rowSpan="3">
                                                {this.state.companySetup.address} <br />
                                                TEL: {this.state.companySetup.phone} <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>WRITER'S SALES COMMISSION REPORT</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table id="tbContent" className="table table-condensed" style={colstyle}>
                                    <tr>
                                        <th>Period</th>
                                        <th>Writer</th>
                                        <th>Processed On</th>
                                        <th>Processed By</th>
                                        <th>Gross Sales</th>
                                        <th>Bonus Rate (%)</th>
                                        <th>Total Bonus</th>
                                    </tr>
                                    {this.props.agents.map(agent => (
                                        <tr>
                                            <td>{this.props.formatPeriod(agent.startDate, agent)}</td>
                                            <td>{this.props.formatWriter(agent.username, agent)}</td>
                                            <td>{this.props.formatDate(agent.createdon, agent)}</td>
                                            <td>{this.props.formatUser(agent.createdby, agent)}</td>
                                            <td>{this.props.formatCur(agent.totalSales, agent)}</td>
                                            <td>{this.props.formatCur(agent.rate, agent)}</td>
                                            <td>{this.props.formatCur(agent.bonus, agent)}</td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}